#readOurBlog {
    background-color: #FBF4E7;
    padding-bottom: 50px;
    a:hover {
        text-decoration: none;
    }
    .text-header {
        width: 80%;
        padding-top: 70px;
        margin-bottom: 35px;
        color: #AE997F;
        margin-left: auto;
        margin-right: auto;
        h2 {
            font-family: 'Assistant';
            font-weight: 700;
            font-size: 42px;
        }
        p {
            font-family: 'Assistant';
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
        }
    }
    .blogContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        .blogInfo {
            width: 30%;
            img {
                width: 100%;
            }
            
            h3 {
                font-family: "Assistant";
                font-weight: 700;
                font-size: 28px;
                color: #AE997F;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            p {
                font-family: "Assistant";
                font-weight: 400;
                font-size: 13px;
                color: #AE997F;
            }
        }
    }
    .readmoreContainer {
        font-family: "Assistant";
        font-weight: 600;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        color: #866339;
        cursor: pointer;
        a {
            color: #866339;
        }
        a:hover {
            font-family: "Assistant";
            font-weight: 700;
        }
    }
    .readmoreContainer:hover {
        font-family: "Assistant";
        font-weight: 700;
    }
}

#readOurBlogMobile {
    background-color: #FBF4E7;
    padding-bottom: 50px;
    padding-top: 30px;
    a:hover {
        text-decoration: none;
    }
    .text-header {
        width: 80%;    
        margin-bottom: 35px;
        color: #AE997F;
        margin-left: auto;
        margin-right: auto;
        h2 {
            font-family: 'Assistant';
            font-weight: 700;
            font-size: 30px;
            margin-bottom: 35px;
        }
        p {
            font-family: 'Assistant';
            font-weight: 600;
            font-size: 19px;
            line-height: 25px;
        }
        a:hover {
            text-decoration: none;
        }
    }
    .blogContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        .blogInfo {
            width: 100%;
            margin-bottom: 20px;
            img {
                width: 100%;
            }
            h3 {
                font-family: "Assistant";
                font-weight: 700;
                font-size: 28px;
                color: #AE997F;
                margin-top: 20px;
                margin-bottom: 10px;
            }
            p {
                font-family: "Assistant";
                font-weight: 400;
                font-size: 13px;
                color: #AE997F;
            }
        }
    }
    .readmoreContainer {
        font-family: "Assistant";
        font-weight: 600;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        color: #866339;
        cursor: pointer;
        a {
            color: #866339;
        }
        a:hover {
            font-family: "Assistant";
            font-weight: 700;
        }
    }
    .readmoreContainer:hover {
        font-family: "Assistant";
        font-weight: 700;
    }
}