#downloadModalContainer {
    // display: none; /* Hide the modal by default */
    position: fixed;    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    .downloadModal {
      background-color: #EDE4D4;;
      width: 40%;
      height: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 30px 40px;
      overflow-y: auto;
      border-radius: 20px;
      h2 {
        font-family: "Assistant";
        font-weight: 700;
        font-size: 28px;
        color: #AE997F;
        margin-bottom: 30px;
      }
      p { 
        font-family: "Assistant";
        font-weight: 500;
        font-size: 13px;
        color: #AE997F;
      }
      input {
        padding-left: 20px;
        border: 2px solid #AE997F80;
        background-color: #EDE4D4;
        color: #AE997F;
        border-radius: 10px;
        height: 50px;
        padding-left: 20px;
      }
      input::placeholder  {
        color: #AE997F;
      }
      .checkbox {
        height: 10px;
        margin-right: 10px;
        color: red;
      }
      .headModal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .cross {
            cursor: pointer;
        }
      }
      .nameInput {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
        .firstName, .lastName {
            width: 46%;
        }
        
      }
      .emailInput {
        width: 100%;
        margin-bottom: 40px;
        .emailName {
            width: 100%;
        }
      }
      .downLoadFile {
        display: flex;
        width: 100%;
        justify-content: center;
        button {
            font-family: "Assistant";
            font-weight: 700;
            font-size: 20px;
            background-color: #866339;
            border: 1px solid #866339;
            border-radius: 28px;
            color: #CEBAA4;
            padding: 5px 30px;
            align-self: center;
        }
      }
    }
    .downloadDone {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #EDE4D4;;
      width: 40%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 30px 40px 30px 40px;
      margin: 0;
      overflow-y: auto;
      border-radius: 20px;
      h2 {
        font-family: "Assistant";
        font-weight: 700;
        font-size: 28px;
        color: #AE997F;
        text-align: center;
      }
    }
}
#downloadModalContainerMobile {
    position: fixed;    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    .downloadModal {
      background-color: #EDE4D4;;
      width: 90%;
      height: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 30px 40px;
      overflow-y: auto;
      border-radius: 20px;
      h2 {
        font-family: "Assistant";
        font-weight: 700;
        font-size: 26px;
        color: #AE997F;
        margin-bottom: 30px;
      }
      p { 
        font-family: "Assistant";
        font-weight: 500;
        font-size: 13px;
        color: #AE997F;
      }
      input {
        padding-left: 20px;
        border: 2px solid #AE997F80;
        background-color: #EDE4D4;
        color: #AE997F;
        border-radius: 10px;
        height: 50px;
        padding-left: 20px;
      }
      input::placeholder  {
        color: #AE997F;
      }
      .checkbox {
        height: 10px;
        margin-right: 10px;
        background-color: green; 
      }
      .headModal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .cross {
            cursor: pointer;
        }
      }

      .inputContainer {
        width: 100%;
        .firstName, .lastName, .emailName {
            width: 100%;
            margin-bottom: 20px;
        }
      }
      .downLoadFile {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 20px;
        button {
            font-family: "Assistant";
            font-weight: 700;
            font-size: 20px;
            background-color: #866339;
            border: 1px solid #866339;
            border-radius: 28px;
            color: #CEBAA4;
            padding: 5px 30px;
            align-self: center;
        }
      }
    }
    .downloadDone {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #EDE4D4;;
      width: 80%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px;
      margin: 0;
      overflow-y: auto;
      border-radius: 20px;
      h2 {
        font-family: "Assistant";
        font-weight: 700;
        font-size: 24px;
        color: #AE997F;
        text-align: center;
      }
    }
}