#reviews {
    background-color: #ffffff;
    .text-header {
        width: 80%;
        padding-top: 70px;
        margin-bottom: 35px;
        font-family: 'Assistant';
        font-weight: 700;
        color: #AE997F;
        font-size: 42px;
        margin-left: auto;
        margin-right: auto;
    }
    .reviews {
        width: 28%;
        img {
            border: 1px solid transparent;
            border-radius: 10px;
        }
    }
    .reviewsContainer {
        width: 85%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 70px;
        
        .reviewsText {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            position: relative;
            .qtop, .qbot {
                position: absolute;
                height: 13px;
            }
            .qtop {
                top: -5px;
                left: 10px;
            }
            .qbot {
                bottom: 5px;
                right: 10px;
            }
            p {
                width: 90%;                
                border: 1px solid #FBF4E7;
                border-radius: 10px;
                font-family: 'Assistant';
                font-weight: 700;
                font-size: 20px;
                color: #AE997F;
                background-color: #FBF4E7;
                padding: 20px;
                // text-align: justify;
                // text-justify: inter-word;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
            }
        }
    }
    h2 {
        text-align: center;
        color: #AE997F;
    }
    .referenceLogo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 90%;
        margin: 0 auto;
        color: #AE997F;
        .imgContainer {
            width: 20%;
            align-self: center;
            margin-right: 40px;
            margin-left: 40px;
        }
    }
}

#reviewsMobile {
    padding-top: 70px;
    background-color: #ffffff;
    .text-header {
        width: 80%;
        margin-bottom: 35px;
        font-family: 'Assistant';
        font-weight: 700;
        color: #AE997F;
        font-size: 42px;
        margin-left: auto;
        margin-right: auto;
    }
    .reviewsContainer {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 70px;
        .reviews {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;
            img {
                border: 1px solid transparent;
                border-radius: 10px;
            }

        }

        .reviewsText {
            display: flex;
            justify-content: center;
            margin-top: 40px;
            position: relative;
            .qtop, .qbot {
                position: absolute;
                height: 13px;
            }
            .qtop {
                top: -5px;
                left: -5px;
            }
            .qbot {
                bottom: 5px;
                right: -5px;
            }
            p {
                width: 100%;                
                border: 1px solid #FBF4E7;
                border-radius: 10px;
                font-family: 'Assistant';
                font-weight: 700;
                font-size: 19px;
                color: #AE997F;
                background-color: #FBF4E7;
                padding: 20px;
                // text-align: justify;
                // text-justify: inter-word;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
            }
        }
    }
    h2 {
        text-align: center;
        color: #AE997F;
    }

    .referenceLogo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #AE997F;
        padding-bottom: 40px;
        img {
            width: 100%;
            // margin: 0 auto;
        }
        a {
            width: 50%;
            margin: 0 auto;  
        }
    }
}