@import "../../index";

.home {

  :global(.register-homepage) {
    width: 50%;
    margin: 0 auto;
    box-shadow: 0px 0px 6px rgba(61, 61, 61, 0.28);
    border-radius: 8px;
    padding: 5% 0;
  }
  
  @include media-breakpoint-down(sm) {
    :global(.text) {
      &-h3 {
        @include text-style(14px, 22px, $font-weight-medium);
      }

      &-header {
        @include text-style(22px, 24px, $font-weight-medium);
      }

      &-menu {
        &-title {
          @include text-style(18px, 24px, $font-weight-medium);
        }

        &-subtitle {
          @include text-style(18px, 32px, $font-weight-medium);
        }
      }

      &-address {
        @include text-style(15px, 21px);
      }
    }
  }

  :global(.header) {
    margin-bottom: 76px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 44px;
    }

    :global(.text-header) {
      font-weight: #{$font-weight-normal};
      // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 2rem;

      @include media-breakpoint-down(sm) {
        // text-shadow: none;
        margin-bottom: 0;
        @include text-style(18px, 33px, $font-weight-bolder);
      }
    }

    :global(.features) {
      @include media-breakpoint-down(sm) {
        white-space: initial;
      }
    }

    :global(.carousel) {
      &-indicators {
        bottom: -76px;

        @include media-breakpoint-down(sm) {
          bottom: -56px;
        }

        li {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: gray("600");

          @include media-breakpoint-down(sm) {
            width: 12px;
            height: 12px;
          }

          &:global(.active) {
            background-color: #{$black};
          }
        }
      }

      &-caption {
        width: 100%;
        height: 100%;
        padding: 3rem 0;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: transparent;
        text-align: left;
        color: #{$black};
      }
    }

    :global(.app-download) {
      display: inline-block;
      position: relative;
      margin-left: -10px;
      
      a {
        &::after {
          width: 50%;
          left: 0;
          right: auto;
        }

        & + a {
          &::after {
            width: 50%;
            left: auto;
            right: 0;
          }
        }
      }
    }
  }

  :global(.feature-platform) {
    button {
      @include media-breakpoint-down(sm) {
        width: 90%;
      }

      img {
        @include media-breakpoint-down(sm) {
          float: right;
          margin-right: 10px;
          margin-left: -10px;
          margin-top: 7px;
        }
      }
    }
  }

  :global(.feature-list) {
    padding-bottom: 10rem;
    background: transparent var(--home-bg-feature-acc-desktop) no-repeat;
    background-size: 115% auto;
    background-position: center bottom;

    @include media-breakpoint-down(sm) {
      padding-bottom: 6rem;
      background-size: auto 78%;
      background-position: left bottom;
    }

    :global(.feature) {
      &-application {
        padding: 4rem 0 3rem;

        @include media-breakpoint-up(sm) {
          background: transparent var(--home-bg-feature-app-desktop) no-repeat;
          background-size: 50% 100%;
          background-position: right center;
        }
      }

      &-website {
        padding: 4rem 0;
      }

      &-accounting {
        padding: 4rem 0;
      }
    }
  }

  :global(.customer) {
    padding-bottom: 10rem;

    @include media-breakpoint-down(sm) {
      padding-bottom: 6rem;
    }

    :global(.total) {
      width: 100%;
      border: 2px solid gray("300");
      border-radius: 8px;

      @include media-breakpoint-down(sm) {
        border: none;
      }

      :global(.box) {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 34px 0;
        padding: 0 1rem;
        border-right: 2px solid gray("300");

        &:last-child {
          border-right: none;
        }

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          text-align: center;
          min-width: 110px;
          margin: 0 1rem 1rem 0;
          padding: 1rem;
          border-radius: 5px;
          border: 1px solid gray("300") !important;
        }

        img {
          margin-right: 1rem;
        }

        h6 {
          margin: 0;
          @include text-style(36px, 54px, $font-weight-bolder);
          @include media-breakpoint-down(sm) {
            @include text-style(16px, 24px);
          }
        }

        p {
          margin: 0;
          @include text-style(16px, 24px);
          @include media-breakpoint-down(sm) {
            @include text-style(10px, 12px);
          }
        }
      }
    }

    img {
      width: auto;
      height: auto;
    }
  }

  :global(.feedback-home) {
    margin-bottom: 5rem; // For slide arrows.
    padding-bottom: 7.5rem;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
      padding-bottom: calc(6rem);
    }

    @include media-breakpoint-up(sm) {
      background-image: var(--home-bg-feedback-desktop);
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: -25% 10%;
    }

    :global(.feedback-desktop) {
      :global(.slick-list) {
        padding-left: 0 !important;
      }

      :global(.slick-slide) {
        &:not(:global(.slick-active)) {
          opacity: 0.2;
        }

        & > div {
          display: flex;
          flex-direction: row;

          img {
            display: block;
            border-radius: 10px;
          }

          :global(.text-menu-title) {
            font-weight: #{$font-weight-bold};
          }

          :global(.text-caption) {
            line-height: 1.5;
          }

          :global(.comment) {
            position: relative;
            background-color: #f2f3f5;
            padding: 5%;
            width: 90%;

            &:before {
              content: var(--icon-double-quote-start);
              position: absolute;
              left: -2%;
              top: 5%;
            }

            &:after {
              content: var(--icon-double-quote-end);
              position: absolute;
              right: -3%;
              bottom: -5%;
            }

            p {
              font-size: 24px;
              font-weight: 500;
            }
          }

          :global(.credit) {
            padding: 5%;
          }

          a {
            padding: 5%;
            width: 400px;

            img {
              display: inline;
              width: 24px;
              height: auto;
            }
          }
        }
      }

      :global(.slick-arrow) {
        top: auto;
        right: auto;
        bottom: -7.5rem;
        left: 0;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: 1px solid #{$black};
        z-index: 1;

        &::before {
          display: none;
        }

        &:global(.slick-prev) {
          background: color("white") var(--icon-arrow-slide-left-dark) no-repeat center center;
        }

        &:global(.slick-next) {
          left: calc(1rem + 64px);
          background: #{$black} var(--icon-arrow-slide-next) no-repeat center center;
          filter: grayscale(0);
        }
      }
    }

    :global(.feedback-mobile) {
      :global(.slick-slide) {
        &:not(:global(.slick-active)) {
          opacity: 0.2;
        }

        & > div {
          display: flex;
          flex-direction: column;

          :global(.comment) {
            position: relative;
            padding: 5%;
            width: 90%;

            &:before {
              content: var(--icon-double-quote-start);
              position: absolute;
              left: -2%;
              top: 0;
            }

            &:after {
              content: var(--icon-double-quote-end);
              position: absolute;
              right: 0;
              bottom: 0;
            }

            p {
              @include text-style(16px, inherit, $font-weight-bold);
            }
          }

          :global(.credit) {
            padding-left: 5%;
            margin-bottom: 5%;
            display: flex;
            flex-direction: row;

            :global(.image-cover) {
              width: 100px !important;
              height: 100px !important;
              position: absolute;
              border-radius: 50%;
              box-shadow: 0 0 2px #3b3b3b;
            }

            :global(.name) {
              flex-direction: column;
              margin: 6%;
              padding-left: 100px;
            }
          }

          a {
            margin: 5%;
            @include text-style(16px, inherit, $font-weight-bold);

            img {
              display: inline;
              width: 16px;
              height: auto;
            }
          }
        }
      }
    }
  }

  :global(.reason) {
    padding-bottom: 10rem;

    @include media-breakpoint-down(sm) {
      padding-bottom: 6rem;
    }

    :global(.text-header) {
      @include media-breakpoint-down(sm) {
        @include text-style(18px, 24px);
      }
    }

    h3 {
      margin: 0;
      @include text-style(12px, 18px, $font-weight-bolder);
      @include media-breakpoint-up(sm) {
        @include text-style(20px, 34px);
      }
    }

    p {
      margin-top: 2rem;

      @include text-style(16px, 24px);
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    :global(.box) {
      width: 100%;

      @include media-breakpoint-down(sm) {
        padding: 0 0.5rem;
        overflow: auto;
      }

      :global(.reason-box) {
        width: 100%;
        height: 100%;
        padding: 1rem;
        background: color("white");
        box-shadow: 0px 0px 6px rgba(61, 61, 61, 0.28);
        border-radius: 10px;

        @include media-breakpoint-down(sm) {
          width: 192px;
          box-shadow: 0px 0px 3px rgba(61, 61, 61, 0.28);
          border-radius: 5px;
        }
      }

      img {
        display: block;
        width: auto;
        height: 100px;
        margin-bottom: 1rem;

        @include media-breakpoint-down(sm) {
          height: 52px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  :global(.register) {
    padding-bottom: 10rem;

    @include media-breakpoint-down(sm) {
      padding-bottom: 6rem;
    }

    h2 {
      @include text-style(28px, 42px, $font-weight-medium);

      span {
        color: color("red");
      }
    }

    p {
      @include text-style(16px, 24px, $font-weight-medium, #808080);
    }

    label {
      span {
        color: color("red");
      }
    }

    input {
      border-radius: 0;
    }

    :global(.box) {
      width: 75%;
      margin: 0 auto;
      padding: 60px 120px;
      background-color: color("white");
      box-shadow: 0px 0px 6px #e2e2e2;
      border-radius: 10px;
    }
  }

  :global(.guarantee) {
    padding-bottom: 10rem;
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding-bottom: 6rem;
    }

    :global(.container) {
      @include media-breakpoint-down(sm) {
        width: 80%;
      }
    }

    h3 {
      @include text-style(28px, 42px, $font-weight-medium);
    }

    a {
      width: 100%;
      margin-top: 34px;
      border-radius: 22px;
      text-align: center;
      @include text-style(16px, 24px, $font-weight-bolder, $white);
    }
  }

  button {
    border-radius: 23px;
    width: 200px;
    height: 45px;

    :global(.arrow) {
      width: auto;
      height: auto;
      margin-left: 7px;
      margin-top: -2px;
    }
  }

  :global(.arrow) {
    width: auto;
    height: auto;
    margin-left: 10px;
  }

 
}
