#whyLussopack {
    height: 100%;

    .text-header {
        margin-top: 20px;
        font-family: "Assistant";
        font-weight: 700;
        font-size: 42px;
        letter-spacing: 3px;
        color: #AE997F;
    }
    hr.line {
        border: 1px solid #AE997F;
    }
      
    .topContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #EDE4D4;
        padding-bottom: 30px;
        .reasonWhyLussopack {
            display: flex;
            flex-direction: row;      
            width: 90%;     
            .reasonContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 95px;
                width: 25%;      
                padding: 0px 35px;
                border-right: 2px solid #AE997F;          
                img {
                    margin-right: 35px;
                }
                p {
                    font-family: "Assistant";
                    font-weight: 600;
                    color: #AE997F;
                    text-align: center;
                    font-size: 20px;
                    width: 160px;
                    line-height: 25px;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
            .reasonContainer:last-child {
                border-right: none;
            }
        }
    }
    .botContainer {
        padding-top: 70px;
        padding-bottom: 70px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        background-color: #ffffff;
        .textContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 35%;
            margin-right: 40px;            
            h2 {
                color: #AE997F;
                font-family: "Assistant";
                font-weight: 700;
                font-size: 42px;
                text-align: center;
            }
            p {
                color: #AE997F;
                font-family: "Assistant";
                font-weight: 500;
                font-size: 20px;
                text-align: center;
            }
        }
        .imageContainer {
            width: 50%;
            img {
                width: 100%;
            }
        }
    }
}
#whyLussopackMobile {
    height: 100%;
 
    .text-header {
        margin-top: 20px;
        font-family: "Assistant";
        font-weight: 700;
        font-size: 38px;
        letter-spacing: 3px;
        color: #AE997F;
        width: 100%;
        margin-bottom: 35px;
    }
    hr.line {
        width: 40%;        
        border: 1px solid #AE997F;
    }
      
    .topContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #EDE4D4;
        padding-bottom: 30px;
        height: auto;
        .reasonWhyLussopack {
            display: flex;
            flex-direction: column;   
            align-items: center;
            .reasonContainer {
                display: flex;
                flex-direction: column;
                align-items: center;   
                margin-bottom: 35px;
                img {
                    margin-bottom: 20px;
                }
                p {
                    font-family: "Assistant";
                    font-weight: 600;
                    color: #AE997F;
                    text-align: center;
                    font-size: 20px;
                    line-height: 25px;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }
    }
    .botContainer {
        padding-top: 35px;
        width: 100%;
        display: flex;
        flex-direction: column; 
        align-items: center;
        background-color: #ffffff;
        padding-bottom: 20px;
        .textContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;          
            h2 {
                color: #AE997F;
                font-family: "Assistant";
                font-weight: 700;
                font-size: 28px;
                text-align: center;
                margin-top: 40px;
            }
            p {
                color: #AE997F;
                font-family: "Assistant";
                font-weight: 500;
                font-size: 15px;
                text-align: center;
            }
        }
        .imageContainer {
            width: 85%;
            img {
                width: 100%;
            }
        }
    }
}