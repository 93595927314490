#header-home-silde{
    .btn-light{
        border: 1px solid #1567B4;
        background-color: transparent;
        color: #1567B4;
    }
    .slick-dots  {
        bottom: 30px;
        width: 100%;
        margin: 0;
        list-style: none;
        text-align: center;
    }
    .slick-dots > li > button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: 0;
        background: 0 0;
    }
    .slick-dots > li > button:before {
        font-size: 20px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: '•';
        text-align: center;
        color:  #ede4d480;
    }
    .slick-dots > li.slick-active > button:before {
        color: #ede4d4cc;
    }
}