#packagingAndDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
    .textHeader {
        margin-top: 70px;
        margin-bottom: 70px;
        h2 {
            font-family: 'Assistant';
            font-weight: 700;
            color: #AE997F;
            margin: 0;
        }        
    }
    .zoom {
        transition: transform .2s;
        margin: 0 auto;
      }
      
      .zoom:hover {
        transform: scale(1.05);
      }

    .containerPic1 {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;        
        .leftContainer {
            margin-right: 40px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            .namePackaging {
                text-align: right;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 20px;
                position: absolute;
                width: 100%;
                bottom: -16px;                
                background-color: #E5D8C7CC;
                color: #866339;
                font-family: 'Assistant';
                font-weight: 700;
                font-size: 28px;
            }
        }
        .rightContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            .childContainer {
                position: relative;
                overflow: hidden;
                .namePackaging {
                    text-align: left;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding-left: 20px;
                    position: absolute;
                    width: 100%;
                    bottom: -16px;                
                    background-color: #E5D8C7CC;
                    color: #866339;
                    font-family: 'Assistant';
                    font-weight: 700;
                    font-size: 28px;
                }        
            }
        }
    }

    .containerPic2 {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        .leftContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 40px;
            cursor: pointer;
            .childContainer {
                position: relative;
                overflow: hidden;
                .namePackaging {
                    text-align: right;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    padding-right: 20px;
                    position: absolute;
                    width: 100%;
                    bottom: -16px;                
                    background-color: #E5D8C7CC;
                    color: #866339;
                    font-family: 'Assistant';
                    font-weight: 700;
                    font-size: 28px;
                }  
            }   
        }
        .rightContainer {
            position: relative;
            overflow: hidden;
            cursor: pointer;
            .namePackaging {
                position: absolute;
                text-align: left;
                padding-left: 20px;
                padding-top: 5px;
                padding-bottom: 5px;
                width: 100%;
                bottom: -16px;                
                background-color: #E5D8C7CC;
                color: #866339;
                font-family: 'Assistant';
                font-weight: 700;
                font-size: 28px;
            }
        }
    }

    .containerPic3 {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        .topContainer {
            width: 100%;
            display: flex;
            flex-direction: row;   
            justify-content: space-between;         
            margin-right: 40px;
            margin-bottom: 20px;
            cursor: pointer;
            .childContainer{
                position: relative;
                overflow: hidden;
                .namePackaging {
                    position: absolute;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    width: 100%;
                    bottom: -16px;                
                    background-color: #E5D8C7CC;
                    color: #866339;
                    font-family: 'Assistant';
                    font-weight: 700;
                    font-size: 28px;
                }
                .left{
                    text-align: right;
                    padding-right: 20px;
                }
                .right {
                    text-align: left;
                    padding-left: 20px;
                }
            }
        }
        .botContainer {
            position: relative;
            overflow: hidden;
            cursor: pointer;
            .namePackaging {
                position: absolute;
                text-align: left;
                padding-left: 20px;
                padding-top: 5px;
                padding-bottom: 5px;
                width: 100%;
                bottom: -16px;                
                background-color: #E5D8C7CC;
                color: #866339;
                font-family: 'Assistant';
                font-weight: 700;
                font-size: 28px;
            }
        }
    }

    .downloadCatalogue {
        display: flex;
        justify-content: center;
        width: 100%;
        button {
            font-family: "Assistant";
            font-weight: 700;
            font-size: 16px;
            color: #CEBAA4;
            background-color: #866339;
            border: 1px solid #866339;
            border-radius: 20px;
            width: 165px;
            height: 53px;
            margin: 70px;
        }
    }

    .thailandCraftsmanship{
        width: 100%;   
        position: relative;     
        img {
            width: 100%;
        }
        .thailandCraftsmanshipText {
            position: absolute;
            top: 50%; 
            transform: translate(0, -50%);
            text-align: center;
            margin-right: auto;
            margin-left: auto;
            width: 100%;
            p {
                font-family: 'Assistant';                
                font-weight: 700;
                color: #FFFFFF99;
                letter-spacing: 25px;
            }
            .thailandText {
                font-size: 160px;
                margin-bottom: 0;
            }
            .craftmanText {
                font-size: 120px;
            }
        }   
    }
}

#packagingAndDisplayMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    .textHeader {
        margin-top: 70px;
        margin-bottom: 70px;
        h2 {
            font-family: 'Assistant';
            font-weight: 700;
            color: #AE997F;
            margin: 0;
        }        
    }       
        .packagingContainer {
            position: relative;
            overflow: hidden;
            width: 80%;
            margin-bottom: 20px;
            cursor: pointer;
            .namePackaging {
                width: 100%;
                text-align: left;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 20px;
                position: absolute;
                bottom: -16px;                
                background-color: #E5D8C7CC;
                color: #866339;
                font-family: 'Assistant';
                font-weight: 700;
                font-size: 24px;
                text-indent: 10px;
            }
        }

    .downloadCatalogue {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 120px;
        button {
            font-family: "Assistant";
            font-weight: 700;
            font-size: 16px;
            color: #CEBAA4;
            background-color: #866339;
            border: 1px solid #866339;
            border-radius: 20px;
            width: 165px;
            height: 53px;
            margin: 70px;
        }
    }

    .thailandCraftsmanship{
        width: 100%;   
        position: relative;     
        img {
            width: 100%;
        }
        .thailandCraftsmanshipText {
            position: absolute;
            top: 50%; 
            transform: translate(0, -50%);
            text-align: center;
            margin-right: auto;
            margin-left: auto;
            width: 100%;
            p {
                font-family: 'Assistant';                
                font-weight: 700;
                color: #FFFFFF99;
                letter-spacing: 25px;
            }
            .thailandText {
                font-size: 160px;
                margin-bottom: 0;
            }
            .craftmanText {
                font-size: 120px;
            }
        }   
    }
}